import styled from "@emotion/styled";
import { Button, Flex, Loader, Table, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ProductionActionApiWorksBulkInputPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import useInventoriesGetQuery from "api/inventories/useInventoriesGetQuery";
import { INVENTORIES_KEY, mutateInventories } from "api/inventories/useInventoriesQuery";
import { WORK_LOGS_KEY } from "api/logs/useWorksLogsQuery";
import { InventoryBarcodeInput } from "components/common/barcode/inventory/inventoriesBarcodeInput";
import { HeaderSubTitle } from "components/common/standard/SubTitle";
import { HeaderTitle } from "components/common/standard/Title";
import { useModal } from "context/ModalStackManager";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { customNotification } from "utils/notificationShow";
import { RawMaterialFormRow } from "./RawMaterialFormRow";

export interface WorkInputFormProps {
    workData: any;
    lotId?: number;
    onInputSuccess?: () => void;
}

interface WorkBulkInputData {
    lotId: number;
    itemCode?: string;
    itemName?: string;
    lotName?: string;
    lotExpireDate?: string;
    locationCode?: string;
    quantity?: string;
}

export interface RawMaterialInputAllFormProps {
    formData: WorkBulkInputData;
    seq: number;
    onChange: (index: number, quantity: string) => void;
    onDelete: (index: number) => void;
}

export interface RoutingBomProps {
    itemCode: string;
    routingCode: string;
    item: {
        name: string;
    };
}

export const RawMaterialInputAllForm = ({
    workData,
    onInputSuccess
}: WorkInputFormProps) => {
    const { closeModal } = useModal();
    const queryClient = useQueryClient();

    const [sourceLocationCode, setSourceLocationCode] = useState("");
    const [targetLocationCode, setTargetLocationCode] = useState("");

    const { mutate: InventoriesAllMutate, isLoading } = useMutation(
        (params: ProductionActionApiWorksBulkInputPutRequest) =>
            mutateInventories.workInputAll(params).mutationFn(undefined),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(INVENTORIES_KEY);
                queryClient.invalidateQueries(WORK_LOGS_KEY);
            }
        }
    )

    // 바코드 스캔했을 때, lotId에 대해서 데이터 처리를 어떻게 할 것인지를 파악하고 로직 구성하면 됨.

    const form = useForm({
        initialValues: {
            barcodeInput: '',
            lotId: '',
            worksBulkInputPutRequest: []
        },
    });

    useInventoriesGetQuery(
        form.values.lotId ? {
            query: {
                $and: [
                    {
                        itemCode: workData?.routingData?.routingBoms?.map((value: RoutingBomProps) => value.itemCode)
                    },
                    {
                        lotId: { $eq: form.values.lotId }
                    },
                    {
                        locationCode:
                            workData?.locationSetting?.fromLocation?.code ??
                            workData?.routingData?.operation?.fromLocationCode ??
                            workData?.productionPlan?.routingsData.find(
                                (data: any) => data.code === workData.routingCode
                            ).operation.fromLocationCode,
                    },
                    {
                        quantity: { $gt: 0 },
                    }
                ],
            }
        } : undefined
        , {
            onSuccess: (data) => {
                if (data.rows && data.rows.length > 0) {
                    const { lotId, itemCode, itemName, lot, locationCode, quantity } = data.rows[0];
                    form.insertListItem('worksBulkInputPutRequest', { lotId, itemCode, itemName, lotName: lot?.name, lotExpireDate: lot?.expiration, locationCode, quantity });
                } else {
                    customNotification.error({ message: '바코드에 해당하는 로트가 존재하지 않습니다.' })
                }
                form.setFieldValue('barcodeInput', '');
                form.setFieldValue('lotId', '');
            }
        })

    useEffect(() => {
        setSourceLocationCode(
            workData?.currentRoutingOutsourceData?.fromLocationCode ??
            workData?.locationSetting?.fromLocation?.code ??
            workData?.routingData?.operation?.fromLocationCode
        );
        setTargetLocationCode(
            // workData?.currentRoutingOutsourceData?.toLocationCode ??
            workData?.equipment?.toLocationCode ??
            workData?.locationSetting?.toLocation?.code ??
            workData?.routingData?.operation?.toLocationCode ??
            workData?.productionPlan?.routingsData.find(
                (data: any) => data.code === workData.routingCode
            ).operation.toLocationCode);
    }, [workData]);

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        if (!inputValue) return
        form.setFieldValue("lotId", inputValue);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (!form.values.barcodeInput) return
            form.setFieldValue("lotId", form.values.barcodeInput);
        }
    }

    const handleRowChange = (index: number, quantity: string) => {
        form.setFieldValue(`worksBulkInputPutRequest.${index}.quantity`, quantity);
    }

    const handleRowDelete = (index: number) => {
        form.removeListItem('worksBulkInputPutRequest', index)
    }

    const onSubmit = () => {
        try {
            const request = form.values.worksBulkInputPutRequest.map((data: WorkBulkInputData) => ({
                workId: workData.id,
                lotId: data.lotId,
                sourceLocationCode,
                targetLocationCode,
                quantity: data.quantity
            }));

            InventoriesAllMutate(
                {
                    worksBulkInputPutRequest: {
                        workLotPairs: request
                    }
                },
                {
                    onSuccess: () => {
                        customNotification.success({
                            message: "원/부자재가 성공적으로 투입되었습니다.",
                        });
                        onInputSuccess && onInputSuccess(); // 실적 저장시 원부자재 투입이 된다면 실적저장 함수를 실행
                        closeModal(form.values);
                    },
                    onError: (error: any) => {
                        customNotification.error({
                            message: error?.response?.data?.message ?? "원/부자재 투입에 실패하였습니다."
                        });
                    }
                }
            );
        } catch (e) {
            customNotification.error({
                message: "원/부자재 투입에 실패하였습니다.",
            });
        }
    };

    if (isLoading) return <Flex w="100%" h="100%" justify="center" align="center"><Loader size="30rem" /></Flex>

    return <Flex w="80rem" direction="column" gap="md" mah="50rem">
        <Flex direction="column">
            <HeaderTitle>바코드 LOT 입력</HeaderTitle>
            <HeaderSubTitle>바코드를 스캔하여 자동으로 데이터를 입력합니다. <br /> 일괄 투입 버튼을 클릭하여 원부자재를 일괄 투입합니다.</HeaderSubTitle>
        </Flex>
        <Flex justify="space-between" gap="md">
            <Flex gap="md">
                <TextInput size="xl" label={<Text fz="1.5rem">출고 로케이션</Text>} disabled value={sourceLocationCode} />
                <TextInput size="xl" label={<Text fz="1.5rem">입고 로케이션</Text>} disabled value={targetLocationCode} />
            </Flex>
            <InventoryBarcodeInput
                handleBlur={handleBlur}
                onKeyDown={handleKeyDown}
                {...form.getInputProps("barcodeInput")}
            />
        </Flex>
        <Table>
            <thead>
                <tr>
                    <Th width={7}><Text fz="2rem">순서</Text></Th>
                    <Th width={17}><Text fz="2rem">품목코드</Text></Th>
                    <Th width={17}><Text fz="2rem">품목명</Text></Th>
                    <Th width={14}><Text fz="2rem">로트 이름</Text></Th>
                    <Th width={18}><Text fz="2rem">로트 유효기한</Text></Th>
                    <Th width={13}><Text fz="2rem">로트 위치</Text></Th>
                    <Th width={14}><Text fz="2rem">수량</Text></Th>
                    <Th width={8}>&nbsp;</Th>
                </tr>
            </thead>
            <tbody>
                {
                    form.values.worksBulkInputPutRequest?.map((formData: WorkBulkInputData, index) =>
                        <RawMaterialFormRow formData={formData} seq={index} key={formData.lotId + index} onChange={handleRowChange} onDelete={handleRowDelete} />
                    )
                }
                {
                    form.values.worksBulkInputPutRequest && form.values.worksBulkInputPutRequest.length === 0 &&
                    <tr>
                        <Td colSpan={7} width={100}>
                            <Text ta="center" fz="2rem">바코드를 스캔하여 LOT를 등록하세요.</Text>
                        </Td>
                    </tr>
                }
            </tbody>
        </Table>
        <Flex justify="flex-end" gap="md">
            <Button size="xl" color="gray" onClick={() => closeModal({})}>취소</Button>
            <Button size="xl" onClick={onSubmit}>일괄 투입</Button>
        </Flex>
    </Flex>
}

const Th = styled.th<{ width?: number }>`
    width: ${(props) => (props.width ? `${props.width}%` : "auto")};
    padding: 7px 10px;
    align-items: flex-start;
`

export const Td = styled.td<{ width?: number }>`
    width: ${(props) => (props.width ? `${props.width}%` : "auto")};
    padding: 7px 10px;
    align-items: flex-start;
`