/**
 * 이 파일은 특정 업체의 기능을 활성화/비활성화하는 데 사용됩니다.
 * 
 * 설명:
 * 이 파일에는 다른 업체에 대한 특정 기능의 가용성을 제어하는 데 사용할 수 있는 변수가 포함되어 있습니다.
 * 이러한 변수의 값을 수정함으로써 특정 업체에 대한 특정 기능을 활성화하거나 비활성화할 수 있습니다.
 * 이를 통해 다른 업체의 요구 사항에 따라 응용 프로그램의 동작을 사용자 정의할 수 있는 유연성을 제공합니다.
 * 
 * 사용법:
 * 이 파일을 사용하려면 여기에서 정의된 변수를 코드베이스의 다른 부분으로 가져올 수 있습니다.
 * 이러한 변수를 참조함으로써 업체에 따라 조건부로 특정 기능을 활성화하거나 비활성화할 수 있습니다.
 * 이를 통해 단일 코드베이스를 다른 클라이언트나 조직에 맞게 사용자 정의할 수 있습니다.
 * 
 * 참고:
 * 이러한 변수의 값이 요구 사항에 맞게 제대로 관리되고 업데이트되도록 주의해야 합니다.
 * 코드에 하드코딩된 값을 피하고 대신 이러한 변수에 의존하여 구성 가능성을 확보하는 것이 중요합니다.
 */

export const customFunctions = {
    // ADD_DOWNTIME_REASON: 비가동사유 신규 추가 기능 활성화 여부
    ADD_DOWNTIME_REASON: process.env.REACT_APP_ADD_DOWNTIME_REASON === 'true' || false,
    ADD_RECYCLE_DEFECT: process.env.REACT_APP_ADD_RECYCLE_DEFECT === 'true' || false,
    // ...다른 업체에 대한 추가 변수를 여기에 추가할 수 있습니다.
}